window.date = require('locutus/php/datetime/date');
window.gmdate = require('locutus/php/datetime/gmdate');

import 'tablesorter/dist/css/theme.default.min.css';
import 'tablesorter/dist/js/jquery.tablesorter.js';
import 'tablesorter/dist/js/jquery.tablesorter.widgets.js';

import 'blueimp-file-upload/js/vendor/jquery.ui.widget.js';
import 'blueimp-file-upload/js/jquery.iframe-transport.js';
import 'blueimp-file-upload/js/jquery.fileupload.js';
import 'blueimp-file-upload/css/jquery.fileupload.css';

import './mediacenter/uploader.js';

import './config/ckeditor/basepath.js';
import 'ckeditor4';
import './config/ckeditor/index.js';

import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;
import 'jquery-ui-bundle';
import './plugins/jquery-ui-1.12.1-smoothness/jquery-ui.css';
import 'jquery-datetimepicker/build/jquery.datetimepicker.full.min.js';
import 'jquery-datetimepicker/build/jquery.datetimepicker.min.css';

import 'datatables.net';
import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables-bootstrap3-plugin';

import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import HC_exportdata from 'highcharts/modules/export-data';

import 'bootstrap/dist/css/bootstrap.css';
//import 'bootstrap/dist/js/bootstrap.bundle.js';
window.bootstrap = require('bootstrap/dist/js/bootstrap.min.js');

window.Highcharts = Highcharts;  
HC_exporting(window.Highcharts);
HC_exportdata(window.Highcharts);

// Load module after Highcharts is loaded
import exportxlsx from './plugins/highcharts-export-xlsx/js/exportxlsx';
exportxlsx(window.Highcharts);
